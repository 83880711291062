import React from 'react'
import calc from '../assets/calculator.svg'
import payment from '../assets/payment.svg'


export default function Admission({text}) {
    
    
  return (
    
    <section className='bgcolorful missionicons container-fluid py-4'>
      <div className='missionicons container p-4'>
      <h1 className='text-success text-center'>Admission</h1>
      <p className='d-flex align-items-center missionparag'>{text.p1}</p>
      <p className='d-flex align-items-center missionparag'>{text.p2}</p>
      <h2 className='d-flex align-items-center text-success py-3'>{text.title}</h2>
      <div className='missionparag d-block d-sm-flex align-items-center'><p className='me-3'><img className='me-2' src={payment} width={40} height={40} alt="" />{text.p3}</p><p><a href={text.link1} target={'_blank'}>{text.link1text}</a></p>
      </div>
      <div className='missionparag d-block d-sm-flex align-items-center'><p className='me-3'><img className='me-2' src={calc} width={40} height={40} alt="" /> {text.p4}</p><p><a href={text.link2} target={'_blank'}>{text.link2text}</a></p>
      </div>
      

      </div>
      
    </section>
  )
}
