import React, {useState,useEffect, useCallback} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import axios from 'axios';

// const locales = {
//   "en-US": require("date-fns/locale/en-US"),
// }

// const localizer = dateFnsLocalizer({
//   format,
//   parse,
//   startOfWeek,
//   getDay,
//   locales
// })
const localizer = momentLocalizer(moment)

export default function Dcalendar() {

  const endpoint ='https://amoursmiatia.com/php/'
  const [events, setEvents] = useState([{
    title:"aaa",
    start:"2022-12-14",
    end:"2022-12-14"
  },
  {
    title:"bbb",
    start:"2022-12-16",
    end:"2022-12-16"
  },

])

  const [myEvents, setMyEvents] = useState([])

  useEffect(() => {
    const Apiget = endpoint+"getcalendar.php";
    fetch(Apiget, {
        "method": "GET",
        "headers": {
        }
})
.then(response => response.json())
.then(data => setMyEvents(data))


.catch(err => {
console.error(err);
});
  }, []);

  const handleSelectSlot = (
    ({ start, end }) => {
      const title = window.prompt('New Event Name')
      if (title) {
        //YYYY-MM-DD HH:MM:SS
        start = moment(start).format('YYYY-MM-DD');
        end = moment(end).format('YYYY-MM-DD');
        //setEvents({ start, end, title })
        setMyEvents((myEvents) => [...myEvents, { start , end, title }])
        
        //console.log(events)
      }
    
    }
    
  )
  
  async function loggg(){

    const response = await axios.post(endpoint+'miatiaAPI.php', {
        request: 'insertevents',
        myEvents
      })
      .then((response)=>{
        console.log(response.data);
        window.alert("Events Saved");
    });
      

  }
  function clearEvents(){
    
      let text = "This will delete all the Events, Proceed?";
      if (window.confirm(text) == true) {
        setMyEvents([])
    console.log("From react "+myEvents)
    const endpoint2 = endpoint+'deleteevents.php';

        fetch(endpoint2, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
                
              },
            body: JSON.stringify(myEvents)
        })
        .then((resp) => resp.json())
        .then(function(response) {
            console.info('Delete response: ', response);
            return response;
        });
      } 
  }
  

  return (
    <div >
      <h1 className='text-success mt-4'>Manage Agenda</h1>
      <h3 className='text-danger'>Add New Event</h3>
      <div className='calwidth2 w-100'>
        <Calendar className='calwidth' localizer={localizer} events={myEvents} onSelectSlot={handleSelectSlot}  selectable style={{height: 500, marginRight: '50px', marginTop: '40px'}}/>
      </div>
      <button className='btn btn-warning mt-3' onClick={loggg}>Save</button>
      <button className='btn btn-danger ms-3 mt-3' onClick={clearEvents}>Delete All</button>

      
    </div>
  )
}
