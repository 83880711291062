import React from 'react'
import fr from '../assets/fr.svg'
import en from '../assets/en.svg'
import { Link } from 'react-router-dom';

export default function Navbar(props) {
  function switchEnglish () {
    props.func("Eng");
    console.log("Eng");
  }
  function switchFrench(){
    props.func("Fr");
    console.log("Fr");
  }
  return (
    <nav className="navbar navbar-expand-md bg-success navbar-dark">
    <div className="container-fluid justify-content-end">
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse justify-content-around" id="collapsibleNavbar">
        <ul className="navbar-nav">
          <li className="nav-item mx-md-0 mx-lg-2">
            <Link className="nav-link" to="/">{props.text.nav1}</Link>
          </li>
          <li className="nav-item mx-md-0 mx-lg-2">
            <Link className="nav-link" to="/About">{props.text.nav2}</Link>
           
          </li>
          <li className="nav-item mx-md-0 mx-lg-2">
            <Link className="nav-link" to="/Gallery">{props.text.nav3}</Link>
          </li>
          <li className="nav-item mx-md-0 mx-lg-2">
          <Link className="nav-link" to="/ProgramEducatif">{props.text.nav4}</Link>
          </li>
          <li className="nav-item mx-md-0 mx-lg-2">
          <Link className="nav-link" to="/Admissions">{props.text.nav5}</Link>
          </li>
          
          <li className="nav-item dropdown">
  <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">{props.text.nav6}</a>
  <ul className="dropdown-menu">
    <li><Link className="dropdown-item" onClick={switchFrench}><img src={fr} alt="" width={15}/> Français</Link>
      </li>
    <li><a className="dropdown-item" href="#/" onClick={switchEnglish}><img src={en} alt="" width={15}/> English</a></li>
    
  </ul>
</li> 
        </ul>
      </div>
    </div>
  </nav> 
  )
}
