import React from 'react'


export default function Homepage(props) {
  return (
    <>
    {props.carousel}
    {props.mission}
    </>
  )
}
