import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react'

export default function Etheme({category}) {
  const endpoint ='https://amoursmiatia.com/php/'
    const [resTheme, setResTheme] = useState({
        title: "",
        color1: "",
        color2: "",
        align: "",
        background: ""
    })
    const [resLines, setResLines] = useState([
        {
        title: "",
        body: "",
        path: "" 
    },{
        title: "",
        body: "",
        path: "" 
    }])


    useEffect(() => {
        gettheme();
        getlines();

      }, []);
      
      async function gettheme(){

        const response = await axios.post(endpoint+'miatiaAPI.php', {
        request: 'gettheme',
        category,
        
      })
      .then((response)=>{
        setResTheme(response.data[0]);
        
    });


      }
      async function getlines(){

        const response = await axios.post(endpoint+'miatiaAPI.php', {
        request: 'getlines',
        category,
        
      })
      .then((response)=>{
        setResLines(response.data);
        
    });

        
      }
  return (
    <div className={"w-75 p-3 eventpreview "+ resTheme.background}>
            <div className="event-content">
                
                <div className="row">
                    <div className="col-12 py-5">
                        <h1 className={'text-center '+ resTheme.color1}>{resTheme.title}</h1>
                        {resLines.map((eventList, index)=>(
                        <article className='w-100 px-2' key={index +1}>
                            <h3 className={'text-center ' + resTheme.color2} key={index +2}>{eventList.title}</h3>
                            <p className={resTheme.color2 +" "+ resTheme.align} key={index +3}>{eventList.body}</p>
                            
                            {eventList.path!==""?<a href={eventList.path} target='_blank'><img src='images/downloadbtn.png'/></a>:null}
                        </article>))}
                    </div>
                </div>
            </div>
            
    </div>
  )
}
