import React, {useState, useEffect} from 'react';

import axios from 'axios';



export default function Ecalendar({language}) {

  

  const [entries, setEntries] = useState([]);
    const [loading, setLoading] = useState(true);
    const endpoint = "https://amoursmiatia.com/php/agenda.php";
  
    useEffect(() => {
      fetchEntries();
    }, []);
  
    const fetchEntries = () => {
      axios.get(endpoint)
        .then((response) => {
          setEntries(response.data.entries);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    const DateDisplay = ({ selectedDate, selectedLanguage }) => {
      const formattedDate = new Intl.DateTimeFormat(selectedLanguage, {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      }).format(new Date(selectedDate));
      return <div>{formattedDate}</div>;
  };



  return (
    <div>
    {loading ? (
      <p>Loading previous entries...</p>
    ) : entries.length > 0 ? (
      <div className='row mt-4 '>
        {entries.map((entry) => (
          <div key={entry.id} className="col-12 my-3 p-3 agendaItem">
            <p className='agendatitle'>{entry.title}</p>
            <p><img src='../images/calendarico.png' width={60} height="auto"/><DateDisplay selectedDate={entry.date} selectedLanguage={language} /></p>
            <p><a href={"https://amoursmiatia.com/php/"+entry.file_path} target="_blank"><img src='../images/pdfico.png' width={40} height="auto"/> Download / Télécharger</a></p>
            
          </div>
        ))}
      </div>
    ) : (
      <p>No data available.</p>
    )}
  </div>
  )
}
