import axios from 'axios';
import React, { useState, useEffect } from 'react'
import foodmenu from '../assets/foodbowl.png'
import miatialogo from '../assets/logo.png'
// import { Document, Page } from 'react-pdf';


export default function PrintFoodMenu({myref}) {
    const endpoint ='https://amoursmiatia.com/php/'
    const [food,setFood] = useState({
        id: "",
        mBreakftast : "",
        mSnackAM : "",
        mSoup : "",
        mMain : "",
        mDessert : "",
        mSnackPM : "",
        tBreakftast : "",
        tSnackAM : "",
        tSoup : "",
        tMain : "",
        tDessert : "",
        tSnackPM : "",
        wBreakftast : "",
        wSnackAM : "",
        wSoup : "",
        wMain : "",
        wDessert : "",
        wSnackPM : "",
        thBreakftast : "",
        thSnackAM : "",
        thSoup : "",
        thMain : "",
        thDessert : "",
        thSnackPM : "",
        fBreakftast : "",
        fSnackAM : "",
        fSoup : "",
        fMain : "",
        fDessert : "",
        fSnackPM : ""
    })
    useEffect(() => {

        getfood();

      }, []);

      async function getfood(){
        const  response = await axios.get(endpoint+'miatiaAPI.php?request=getfood');
        setFood(response.data[0]);
      }
 
  return (
    
    <section ref={myref} style={{width:1000+'px',overflow:'visible',display:'none'}}>
        <h1 className='w-75 mx-auto text-success text-center py-1 mt-2'><img className="img-fluid" src='images/menu.jpeg' alt="" width = {200} /></h1>
        <div className='w-100 mx-auto p-1'>
        
        <table className='table tablew'>
            
        <caption>Bonne Apétit</caption>
            <thead>
                <tr className='foodtable'>
                    <th></th>
                    <th>Breakfast</th>
                    <th>Snack AM</th>
                    <th>Soup or Salad</th>
                    <th>Main Course</th>
                    <th>Dessert</th>
                    <th>Snack PM</th>
                </tr>
            </thead>
            <tbody>
                <tr className='foodtable'>
                    <th>Monday</th>
                    <td>{food.mBreakftast}</td>
                    <td>{food.mSnackAM}</td>
                    <td>{food.mSoup} </td>
                    <td>{food.mMain}</td>
                    <td>{food.mDessert}</td>
                    <td>{food.mSnackPM}</td>
                </tr>
                <tr className='foodtable'>
                    <th>Tuesday</th>
                    <td>{food.tBreakftast}</td>
                    <td>{food.tSnackAM}</td>
                    <td>{food.tSoup}</td>
                    <td>{food.tMain}</td>
                    <td>{food.tDessert}</td>
                    <td>{food.tSnackPM}</td>
                </tr>
                <tr className='foodtable'>
                    <th>Wednesday</th>
                    <td>{food.wBreakftast}</td>
                    <td>{food.wSnackAM}</td>
                    <td>{food.wSoup}</td>
                    <td>{food.wMain}</td>
                    <td>{food.wDessert}</td>
                    <td>{food.wSnackPM}</td>
                </tr>
                <tr className='foodtable'>
                    <th>Thursday</th>
                    <td>{food.thBreakftast}</td>
                    <td>{food.thSnackAM}</td>
                    <td>{food.thSoup}</td>
                    <td>{food.thMain}</td>
                    <td>{food.thDessert}</td>
                    <td>{food.thSnackPM}</td>
                </tr>
                <tr className='foodtable'>
                    <th>Friday</th>
                    <td>{food.fBreakftast}</td>
                    <td>{food.fSnackAM}</td>
                    <td>{food.fSoup}</td>
                    <td>{food.fMain}</td>
                    <td>{food.fDessert}</td>
                    <td>{food.fSnackPM}</td>
                </tr>
            </tbody>
            
        </table>
      
        </div>
    </section>
  

  )
}
