import React from 'react'
import logo from '../logo.png'
import { Link } from 'react-router-dom'

import { EnvelopePaper, GeoAlt, People, Telephone } from 'react-bootstrap-icons'

export default function Header(props) {
  return (
    <header className="d-flex container-fluid p-0 bg-white">
        <div className="d-flex justify-content-center align-items-center bg-warning logo-container px-2">
            <img className="img-fluid" src={logo} alt="" width = {100} height={100} autoFocus/>
        </div>
        
            <div className="row container-fluid g-0">
                <div className="col-3 text-center py-1 top-section"><Link className="nav-link" to="Visiter"><GeoAlt size={35}/><span className='d-none d-sm-block'>{props.text.head1}</span></Link></div>
                <div className="col-3 text-center py-1 top-section"><a className='text-dark alink d-block w-100' href="tel:+15144499050"><Telephone size={35}/><span className='d-none d-sm-block'>{props.text.head2}</span></a></div>
                <div className="col-3 text-center py-1 top-section"><Link className="nav-link" to="Contact"><EnvelopePaper size={35}/><span className='d-none d-sm-block'>{props.text.head3}</span></Link></div>
                <div className="col-3 text-center py-1 top-section"><Link className="nav-link" to="Parents"><People size={35}/><span className='d-none d-sm-block'>{props.text.head4}</span></Link></div>
                <div className="col-12">
                    {props.nav}
                </div>
               
            </div>
        </header>
    
  )
}
