import React from 'react'
import duck from '../assets/duck.svg'
import heart from '../assets/heart.svg'
import flower from '../assets/flower.svg'
import star from '../assets/star.svg'
import sun from '../assets/sun.svg'
import { useState } from 'react'
import MyModal from './MyModal'



export default function Progeduc({texts}) {
  const text = texts.progeduc;
  const textmia2 = texts.miatia2;
  const [showModal, setShowModal] = useState(false);
  const handleClick = () => {
    setShowModal(true);
  };
  return (


<section className='bgcolorful missionicons container-fluid py-4'>
      <div className='missionicons container p-4'>
      <h1 className='text-success text-center'>{text.heading}</h1>
      <h2 className='d-flex align-items-center text-danger py-3'>{text.title1}</h2>
      
      <article className='d-flex justify-content-around eduCard' >
        <div className='missionparag mia2anim' onClick={handleClick}>
            <p className='d-flex justify-content-center'><img src='images/prematernelle.gif' width={80} height={80} alt="" /></p>
            <p className='d-flex justify-content-center'>Mia & Tia 2</p>
            <p className='d-flex justify-content-center'>{text.class6age}</p>
        </div>
        </article>
    <article className='d-flex justify-content-around eduCard'>
        <div className='missionparag'>
            <p className='d-flex justify-content-center'><img src={duck} width={40} height={40} alt="" /></p>
            <p className='d-flex justify-content-center'>Les petits Poupons</p>
            <p className='d-flex justify-content-center'>{text.class1age}</p>
        </div>
    
        <div className='missionparag'>
            <p className='d-flex justify-content-center'><img src={heart} width={40} height={40} alt="" /></p>
            <p className='d-flex justify-content-center'>Les Petits Cœurs</p>
            <p className='d-flex justify-content-center'>{text.class2age}</p>
        </div>
        <div className='missionparag'>
            <p className='d-flex justify-content-center'><img src={flower} width={40} height={40} alt="" /></p>
            <p className='d-flex justify-content-center'>Les Petites Fleurs</p>
            <p className='d-flex justify-content-center'>{text.class3age}</p>
        </div>
        <div className='missionparag'>
            <p className='d-flex justify-content-center'><img src={star} width={40} height={40} alt="" /></p>
            <p className='d-flex justify-content-center'>Les Petites Étoiles</p>
            <p className='d-flex justify-content-center'>{text.class4age}</p>
        </div>
        <div className='missionparag'>
            <p className='d-flex justify-content-center'><img src={sun} width={40} height={40} alt="" /></p>
            <p className='d-flex justify-content-center'>Les Petits Soleils</p>
            <p className='d-flex justify-content-center'>{text.class5age}</p>
        </div>
    
    

    </article>
   

      
      <p className='missionparag'><h3 className='text-warning'>{text.title2}</h3>
        <ul>
            <li>{text.prog11}</li>
            <li>{text.prog12}</li>
            <li>{text.prog13}</li>
            <li>{text.prog14}</li>
            <li>{text.prog15}</li>
            <li>{text.prog16}</li>
            <li>{text.prog17}</li>
        </ul>
    
    
    </p>
      <p className='missionparag'><h3 className='text-warning'>{text.title3}</h3>
    <ul>
        <li>{text.prog21}</li>
        <li>{text.prog22}</li>
    </ul>
    
    </p>
    
    <MyModal
          show={showModal}
          onHide={() => setShowModal(false)}
          text={textmia2}
        />

      </div>
      
    </section>



  )
}
