import React, { useState } from 'react'
import Dactivities from './Devent'
import aleft from '../../../assets/aleft.svg'
import aright from '../../../assets/aright.svg'
import acenter from '../../../assets/acenter.svg'
import Resumable from 'resumablejs';
import axios from 'axios';
import { useEffect } from 'react'

export default function Devents() {
    const endpoint ='https://amoursmiatia.com/php/'
    const [theme, setTheme] = useState("Thème")
    const [textColor, setTextColor] = useState("text-success")
    const [textColorb, setTextColorb] = useState("text-dark")
    const [ebackground, setEbackground] = useState("christmas-1")
    const [bodyTextAlign, setBodyTextAlign] = useState("text-start")
    const [categorie, setCategorie] = useState ("poupons")
    // const [eventLine, setEventLine] = useState([{
    //     title: "",
    //     line: ""
    // }])
    const [eventList, setEventList] = useState([{
        title: "",
        line: "",
        path: ""
    }])
    const handleAddEvent =()=>{
        setEventList([...eventList,{
            title: "",
            line: "",
            path: ""
        }])
    }
    const handelLineChange=(e, index) =>{
        const {name, value} = e.target;
        const list = [...eventList];
        list[index][name] = value;
        setEventList(list)

    }
    const handelfileChange=(e, index, path) =>{
        const {name, value} = e.target;
        const list = [...eventList];
        list[index][name] = path;
        setEventList(list);
        console.log(eventList);

    }
        const [file, setFile] = useState(null);
        const [resumable, setResumable] = useState(null);
        const [progress, setProgress] = useState(0);
        
        useEffect(() => {
            if (resumable) {
              resumable.upload();
            }
          }, [resumable]);

    const handelfile = (event,index) => {
            console.log('upload function called!')
          const newFile = event.target.files[0];
          const path = endpoint+'final/' + newFile.name;
          handelfileChange(event, index, path);
          
      
          const newResumable = new Resumable({
            target: endpoint+'fileupload.php',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              chunkSize: 1 * 1024 * 1024,
            //   fileSize: newFile.size, 
            },
          });
      
          newResumable.addFile(newFile);
          newResumable.on('fileProgress', (file, ratio) => {
            setProgress(parseInt(file.progress()*100));
         });
          setFile(newFile);
          
          newResumable.on('fileAdded', () => {
            newResumable.upload();
          });
          setResumable(newResumable);
          
          
          
        }

    const handelLineRemove = (index) =>{
        const list = [...eventList];
        list.splice(index, 1);
        setEventList(list);

    }
    async function saveLines(){
        const themes = {
            title: theme,
            color1: textColor,
            color2: textColorb,
            align: bodyTextAlign,
            background: ebackground
        }

        const response = await axios.post(endpoint+'miatiaAPI.php', {
        request: 'insertlines',
        eventList,
        categorie,
        themes
      })
      .then((response)=>{
        console.log(response.data);
        window.alert("Saved");
    });
        // const endpoint = 'http://osirisweb.ca/php/setlines.php';

        // fetch(endpoint, {
        //     method: 'POST',
        //     headers: {
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json',
        //         'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
                
        //       },
        //     body: JSON.stringify(eventList)
        // })
        // .then((resp) => resp.json())
        // .then(function(response) {
        //     console.info('fetch()', response);
        //     return response;
        // });
        // saveTheme();
    }
    function saveTheme(){
        const themes = {
            title: theme,
            color1: textColor,
            color2: textColorb,
            align: bodyTextAlign,
            background: ebackground
        }
        const endpoint2 = endpoint+'settheme.php';

        fetch(endpoint2, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
                
              },
            body: JSON.stringify(themes)
        })
        .then((resp) => resp.json())
        .then(function(response) {
            console.info('fetch()', response);
            return response;
        });
        window.alert("Events Saved")
    }
   
  return (
    <div className='row'>
        <div className="col-6">
        <h1 className='text-success py-4'>Manage Thèmes/ Activités</h1> <button className='btn btn-warning' onClick={saveLines}>Save Changes</button><br></br>
        Classe :<select className='form-control' id="categorie" onChange={(e)=>setCategorie(e.target.value)} value={categorie}>
                  <option value="poupons">Petits poupons</option>
                  <option value="coeurs">Petits Coeurs</option>
                  <option value="fleurs">Petites fleures</option>
                  <option value="etoils">Petites étoiles</option>
                  <option value="soleils">Petits Soleils</option>
                  <option value="prematernelle">Prématernelle</option>
               </select>
        </div>
        <div className="col-6">
        <h3 className='text-info w-100 text-end py-4'>Preview</h3>
        </div>
        
        <div className="col-7">
            
            

            <label className='py-2' htmlFor="theme">Thème : </label>
            <ul className='color-swatch'>
                <li className='bg-danger' onClick={()=>setTextColor("text-danger")}></li>
                <li className='bg-primary' onClick={()=>setTextColor("text-primary")}></li>
                <li className='bg-warning' onClick={()=>setTextColor("text-warning")}></li>
                <li className='bg-info' onClick={()=>setTextColor("text-info")}></li>
                <li className='bg-dark' onClick={()=>setTextColor("text-dark")}></li>
                <li className='bg-success' onClick={()=>setTextColor("text-success")}></li>
            </ul>
             <input className='form-control mb-5' value={theme} onChange={(e)=>setTheme(e.target.value)} id='theme' type="text" />

            Background :<select className='form-control' id="eventbg" onChange={(e)=>setEbackground(e.target.value)} value={ebackground}>
                  <option value="nobackground">None</option>
                  <option value="miatialogo">miatia logo</option>
                  <option value="christmas-1">Christmas 1</option>
                  <option value="christmas-2">Christmas 2</option>
                  <option value="christmas-3">Christmas 3</option>
                  <option value="halloween-1">Halloween 1</option>
                  <option value="canada-1">Canada day 1</option>
                  <option value="canada-2">Canada day 2</option>
                  <option value="educatif">Educatif</option>
                  <option value="fetemeres">Fête des mères</option>
                  <option value="feteperes">Fête des pères</option>
                  <option value="quebec-1">Fête Quebec</option>
                  <option value="grace-1">Action grâce</option>
                  <option value="labourday">Labour day</option>
                  <option value="meeting">Parents meeting</option>
                  <option value="mothersday">Mothers day</option>
                  <option value="pappillons">Pappillons</option>
                  <option value="pirates">Pirates</option>
                  <option value="rencontre">Rencontre parents</option>
                  <option value="thanksgiving">Thanksgiving</option>
                  <option value="cirque">Cirque</option>
               </select>
            <button className='btn btn-primary my-3' onClick={handleAddEvent}>Add Event Line</button>
            <ul className='color-swatch'>
                <li className='bg-danger' onClick={()=>setTextColorb("text-danger")}></li>
                <li className='bg-primary' onClick={()=>setTextColorb("text-primary")}></li>
                <li className='bg-warning' onClick={()=>setTextColorb("text-warning")}></li>
                <li className='bg-info' onClick={()=>setTextColorb("text-info")}></li>
                <li className='bg-dark' onClick={()=>setTextColorb("text-dark")}></li>
                <li className='bg-success' onClick={()=>setTextColorb("text-success")}></li>
            </ul>
            <div className="btn-group">
                <button type="button" className="btn btn-light" onClick={()=>setBodyTextAlign("text-start")}><img src={aleft} width={20} height={20} alt="" /></button>
                <button type="button" className="btn btn-light" onClick={()=>setBodyTextAlign("text-center")}><img src={acenter} width={20} height={20} alt="" /></button>
                <button type="button" className="btn btn-light" onClick={()=>setBodyTextAlign("text-end")}><img src={aright} width={20} height={20} alt="" /></button>
            </div>
            
            {eventList.map((eventList, index)=>(

                <div className='mt-1' key={index}>
                <label htmlFor={'eventTitle'+index} key={index +1}>Event Title : </label> <input className='form-control' id={'eventTitle'+index} name='title' type="text" value={eventList.title} onChange={(e)=>handelLineChange(e,index)} key={index +2}/>
                <label htmlFor={"eventBody"+index} key={index +3}>Event : </label><textarea className='form-control' name="line" id={"eventBody"+index} cols="30" rows="3" value={eventList.line} onChange={(e)=>handelLineChange(e,index)} key={index +7}></textarea>
                <label htmlFor={'eventFile'+index} key={index +6}>Attachment : </label><div className="d-flex"> <input className='form-control me-3' id={'eventFile'+index} name='path' type="file"  onChange={(e)=>handelfile(e,index)} key={index +5}/><span className={progress===100?'text-success':''}> {progress}%</span></div>
                <button className='btn btn-danger my-1' onClick={() => handelLineRemove(index)}>Remove Line</button>
            </div>

            ))}
            
        </div>
        <div className={"col-5 p-3 eventpreview "+ ebackground}>
            <div className="event-content">
                
                <div className="row">
                    <div className="col-12">
                        <h1 className={'text-center '+ textColor}>{theme}</h1>
                        {eventList.map((eventList, index)=>(
                        <article className='w-100 px-2' key={index +1}>
                            <h3 className={'text-center ' + textColorb} key={index +2}>{eventList.title}</h3>
                            <p className={textColorb +" "+ bodyTextAlign} key={index +3}>{eventList.line}</p>
                            <p><a target='_blank' href={eventList.path}>Download</a></p>
                        </article>))}
                    </div>
                </div>
            </div>
            
        </div>
        
    </div>
  )
}
