import React from 'react'
import PhotoGallery from './PhotoGallery'

export default function ParentGallery() {
  return (
    <>
    <h1 className='w-75 mx-auto rounded-5 border border-1 border-warning text-success text-center py-1 mt-4'>Gallerie</h1>
    <PhotoGallery />
    </>
  )
}
