import axios from 'axios';
import React, { useState, useEffect } from 'react'
import foodmenu from '../../../assets/food.svg'

export default function FoodMenu() {
    const endpoint ='https://amoursmiatia.com/php/'
    const [food,setFood] = useState({
        id: "",
        mBreakftast : "",
        mSnackAM : "",
        mSoup : "",
        mMain : "",
        mDessert : "",
        mSnackPM : "",
        tBreakftast : "",
        tSnackAM : "",
        tSoup : "",
        tMain : "",
        tDessert : "",
        tSnackPM : "",
        wBreakftast : "",
        wSnackAM : "",
        wSoup : "",
        wMain : "",
        wDessert : "",
        wSnackPM : "",
        thBreakftast : "",
        thSnackAM : "",
        thSoup : "",
        thMain : "",
        thDessert : "",
        thSnackPM : "",
        fBreakftast : "",
        fSnackAM : "",
        fSoup : "",
        fMain : "",
        fDessert : "",
        fSnackPM : ""
    })
    const [menuset, setMenuSet] = useState('menuset1');
    const handleMenuSet = (event) => {
        setMenuSet(event.target.id);
      };


    useEffect(() => {

        getfood();
        
      }, [menuset]);

      async function getfood(){

        const response = await axios.post(endpoint+'miatiaAPI.php', {
        request: 'getmenufood',
        menuset
      })
      .then((response)=>{
        setFood(response.data[0]);
    });


      }
    const handleChange=(e) =>{
        const {name, value} = e.target;
        setFood((prev)=>{
            return {...prev, [name]: value};
        })
       }
    const handleSubmit= async(e)=>{
        e.preventDefault();
        console.log("from React :" + food);

        const response = await axios.post(endpoint+'miatiaAPI.php', {
        request: 'updatefood',
        food,
        menuset
      })
      .then((response)=>{
        console.log(response.data);
        window.alert("Saved");
    });

        // const endpoint = 'http://osirisweb.ca/php/updatefood.php';

        // fetch(endpoint, {
        //     method: 'POST',
        //     headers: {
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json',
        //         'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
                
        //       },
        //     body: JSON.stringify(food)
        // })
        // .then((resp) => resp.json())
        // .then(function(response) {
        //     console.info('fetch()', response);
        //     return response;
        // });
        
    }   
  return (
    <section>
        <h1 className='w-75 mx-auto rounded-5 border border-1 border-warning text-success text-center py-1'><img className="img-fluid" src={foodmenu} alt="" width = {40} height={40}/> Menu <img className="img-fluid" src={foodmenu} alt="" width = {40} height={40}/></h1>
        <div className="d-flex justify-content-center my-4">
        <button id='menuset1' className={['btn btn-warning me-1 ', menuset === "menuset1" ? "border btn-danger" : ""]} onClick={handleMenuSet}>Menu set 1</button>
        <button id='menuset2' className={['btn btn-warning me-1 ', menuset === "menuset2" ? "border btn-danger" : ""]} onClick={handleMenuSet}>Menu set 2</button>
        <button id='menuset3' className={['btn btn-warning me-1 ', menuset === "menuset3" ? "border btn-danger" : ""]} onClick={handleMenuSet}>Menu set 3</button>
        <button id='menuset4' className={['btn btn-warning me-1 ', menuset === "menuset4" ? "border btn-danger" : ""]} onClick={handleMenuSet}>Menu set 4</button>
        <button id='menuset5' className={['btn btn-warning me-1 ', menuset === "menuset5" ? "border btn-danger" : ""]} onClick={handleMenuSet}>Menu set 5</button>
        </div>
        <div className='table-responsive w-75 mx-auto pe-3'>
        <form onSubmit={handleSubmit}>
        <table className='table tablew'>
            
        <caption>Bonne Apétit</caption>
            <thead>
                <tr>
                    <th></th>
                    <th>Breakfast</th>
                    <th>Snack AM</th>
                    <th>Soup or Salad</th>
                    <th>Main Course</th>
                    <th>Dessert</th>
                    <th>Snack PM</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>Monday</th>
                    <td><input type="text" value ={food.mBreakftast} name="mBreakftast" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.mSnackAM} name="mSnackAM" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.mSoup} name="mSoup" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.mMain} name="mMain" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.mDessert} name="mDessert" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.mSnackPM} name="mSnackPM" onChange={handleChange}/></td>
                </tr>
                <tr>
                    <th>Tuesday</th>
                    <td><input type="text" value ={food.tBreakftast} name="tBreakftast" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.tSnackAM} name="tSnackAM" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.tSoup} name="tSoup" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.tMain} name="tMain" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.tDessert} name="tDessert" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.tSnackPM} name="tSnackPM" onChange={handleChange}/></td>
                </tr>
                <tr>
                    <th>Wednesday</th>
                    <td><input type="text" value ={food.wBreakftast} name="wBreakftast" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.wSnackAM} name="wSnackAM" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.wSoup} name="wSoup" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.wMain} name="wMain" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.wDessert} name="wDessert" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.wSnackPM} name="wSnackPM" onChange={handleChange}/></td>
                </tr>
                <tr>
                    <th>Thursday</th>
                    <td><input type="text" value ={food.thBreakftast} name="thBreakftast" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.thSnackAM} name="thSnackAM" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.thSoup} name="thSoup" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.thMain} name="thMain" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.thDessert} name="thDessert" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.thSnackPM} name="thSnackPM" onChange={handleChange}/></td>
                </tr>
                <tr>
                    <th>Friday</th>
                    <td><input type="text" value ={food.fBreakftast} name="fBreakftast" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.fSnackAM} name="fSnackAM" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.fSoup} name="fSoup" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.fMain} name="fMain" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.fDessert} name="fDessert" onChange={handleChange}/></td>
                    <td><input type="text" value ={food.fSnackPM} name="fSnackPM" onChange={handleChange}/></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td><input type="submit" value="Save" className="btn btn-danger"/></td>
                    <td></td>
                </tr>
            </tfoot>
            
        </table>
        </form>
        </div>
    </section>
  )
}
