import React from 'react'
import Dgetweather from './Dgetweather'
import weather from '../assets/weather.svg'
import logo from '../logo.png'

export default function Dwelcome() {
  return (
    <>
    <div className='d-flex justify-content-center'>
      <img className="img-fluid d-block" src={logo} alt="" width = {130} height={130}/>
    </div>
      
      <h1 className='py-3 text-success d-flex align-items-center my-3 border-bottom'><img className="d-block img-fluid me-2" width={80} src={weather} alt="" /> The weather forecast for the upcoming days</h1>
      <section className="forecast-container">

        <Dgetweather/>
      </section>
    </>
  )
}
