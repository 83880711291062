import React, { useState } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';

const numPhotos = 22;
const pathimage = 'images/gallery/'
const photos = Array.from({ length: numPhotos }, (v, i) => ({
    src: `${pathimage}img${i < 9 ? '0' : ''}${i + 1}.jpg`,
    alt: `Image ${i + 1}`,
  }));

const PhotoGallery = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    
    <div className="photo-gallery-container container">
      <Gallery photos={photos} layout="masonry" onClick={openLightbox} className="mx-auto"/>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(photo => ({
                ...photo,
                source: photo.src,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default PhotoGallery;