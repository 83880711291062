import React from 'react'
import carousel01 from '../assets/carousel/carousel01.jpg'
import carousel02 from '../assets/carousel/carousel02.jpg'
import carousel03 from '../assets/carousel/carousel03.jpg'
import left from '../assets/left.svg'
import right from '../assets/right.svg'


export default function Carousel(props) {
  return (
    // Carousel 
<div id="demo" className="carousel slide" data-bs-ride="carousel" data-bs-touch="true" data-bs-interval="4000">

  <div className="carousel-indicators">
    <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
  </div>
  

  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={carousel01} alt="Los Angeles" className="d-block w-100" />
      <div className="carousel-caption">
        <h3>{props.text.scaption1}</h3>
       
      </div>
    </div>
    <div className="carousel-item">
      <img src={carousel02} alt="Chicago" className="d-block w-100" />
      <div className="carousel-caption">
        <h3>{props.text.scaption2}</h3>
        
      </div> 
    </div>
    <div className="carousel-item">
      <img src={carousel03} alt="New York" className="d-block w-100" />
      <div className="carousel-caption">
        <h3>{props.text.scaption3}</h3>
      </div>  
    </div>
  </div>
  
 
  <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
    <img width={30} src={left} alt="" />
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
  <img width={30} className="img-fluid" src={right} alt="" />
  </button>
</div>
  )
}
