import React from 'react'
import permis from '../assets/permis2.svg'
import kids from '../assets/kids.svg'
import home from '../assets/home.svg'


export default function About(props) {
  return (
    <section className="container-fluid bgcolorful py-4">
        <h1 className='w-75 text-center amours mx-auto my-5 text-success'>Les Amours de MIA&TIA</h1>
    <div className="container missionicons p-3">
       
        
        <div className='row mt-4 py-2'>
            <div className="col-12">
                <h2 className='text-success'>{props.text.title}</h2>
            </div>
            <div className="col-12 ">
                <p className='d-flex align-items-center missionparag'><img className='me-4' width={60} src={permis} alt="" />{props.text.about1}</p>
                <p className='d-flex align-items-center missionparag'><img className='me-4' width={60} src={kids} alt="" />{props.text.about2}</p>
                <p className='d-flex align-items-center missionparag'><img className='me-4' width={60} src={home} alt="" />{props.text.about3}</p>
               
</div>
            
        </div>
    </div>
    </section>
  )
}
