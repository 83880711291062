import React from 'react'
import FoodMenu from './FoodMenu'

export default function Parents() {
  return (
    <>
    <div>
       <h1 className='py-4 text-primary'>Manage Parents area</h1>
    </div>
    <FoodMenu/>
   
    </>
  )
}
