import React from 'react'
import { Outlet } from 'react-router-dom'

export default function Miatia(props) {
  return (
    <>
    {props.header}
    <Outlet/>
    {props.footer}
    </>
  )
}
