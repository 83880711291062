import axios from 'axios';
import React, { useState, useEffect } from 'react'
import foodmenu from '../assets/food.svg'
// import { Document, Page } from 'react-pdf';


export default function EFoodMenu({lang}) {
    const endpoint ='https://amoursmiatia.com/php/'
    const [food,setFood] = useState({
        id: "",
        mBreakftast : "",
        mSnackAM : "",
        mSoup : "",
        mMain : "",
        mDessert : "",
        mSnackPM : "",
        tBreakftast : "",
        tSnackAM : "",
        tSoup : "",
        tMain : "",
        tDessert : "",
        tSnackPM : "",
        wBreakftast : "",
        wSnackAM : "",
        wSoup : "",
        wMain : "",
        wDessert : "",
        wSnackPM : "",
        thBreakftast : "",
        thSnackAM : "",
        thSoup : "",
        thMain : "",
        thDessert : "",
        thSnackPM : "",
        fBreakftast : "",
        fSnackAM : "",
        fSoup : "",
        fMain : "",
        fDessert : "",
        fSnackPM : ""
    })
    const translations = {
        en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        fr: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      };
    
      // Determine the current language and select the translations accordingly
      const translatedWeekDays = translations[lang] || translations.en;

      const foodtranslations = {
        en: ['Breakfast', 'Snack AM', 'Soup or Salad', 'Main Course', 'Dessert', 'Snack PM'],
        fr: ['Petit Déjeuner', 'Collation AM', 'Soupe ou Salade', 'Plat Principal', 'Dessert', 'Collation PM'],
      };
    
      // Determine the current language and select the translations accordingly
      const translatedFoodCourses = foodtranslations[lang] || foodtranslations.en;


    useEffect(() => {

        getfood();

      }, []);

      async function getfood(){
        const  response = await axios.get(endpoint+'miatiaAPI.php?request=getfood');
        setFood(response.data[0]);
      }
 
  return (
    
    <section>
        <h1 className='w-75 mx-auto text-success text-center py-1 mt-2'><img className="img-fluid" src='images/menu.jpeg' alt="" width = {200} /></h1>
        <div className='table-responsive w-100 mx-auto p-1'>
        
        <table className='table tablew'>
            
        <caption>Bonne Apétit</caption>
            <thead>
                <tr>
                    <th></th>
                    <th>{translatedFoodCourses[0]}</th>
                    <th>{translatedFoodCourses[1]}</th>
                    <th>{translatedFoodCourses[2]}</th>
                    <th>{translatedFoodCourses[3]}</th>
                    <th>{translatedFoodCourses[4]}</th>
                    <th>{translatedFoodCourses[5]}</th>
                </tr>
            </thead>
            <tbody>
                <tr className='foodtable'>
                    <th>{translatedWeekDays[1]}</th>
                    <td>{food.mBreakftast}</td>
                    <td>{food.mSnackAM}</td>
                    <td>{food.mSoup} </td>
                    <td>{food.mMain}</td>
                    <td>{food.mDessert}</td>
                    <td>{food.mSnackPM}</td>
                </tr>
                <tr className='foodtable'>
                    <th>{translatedWeekDays[2]}</th>
                    <td>{food.tBreakftast}</td>
                    <td>{food.tSnackAM}</td>
                    <td>{food.tSoup}</td>
                    <td>{food.tMain}</td>
                    <td>{food.tDessert}</td>
                    <td>{food.tSnackPM}</td>
                </tr>
                <tr className='foodtable'>
                    <th>{translatedWeekDays[3]}</th>
                    <td>{food.wBreakftast}</td>
                    <td>{food.wSnackAM}</td>
                    <td>{food.wSoup}</td>
                    <td>{food.wMain}</td>
                    <td>{food.wDessert}</td>
                    <td>{food.wSnackPM}</td>
                </tr>
                <tr className='foodtable'>
                    <th>{translatedWeekDays[4]}</th>
                    <td>{food.thBreakftast}</td>
                    <td>{food.thSnackAM}</td>
                    <td>{food.thSoup}</td>
                    <td>{food.thMain}</td>
                    <td>{food.thDessert}</td>
                    <td>{food.thSnackPM}</td>
                </tr>
                <tr className='foodtable'>
                    <th>{translatedWeekDays[5]}</th>
                    <td>{food.fBreakftast}</td>
                    <td>{food.fSnackAM}</td>
                    <td>{food.fSoup}</td>
                    <td>{food.fMain}</td>
                    <td>{food.fDessert}</td>
                    <td>{food.fSnackPM}</td>
                </tr>
            </tbody>
            
        </table>
      
        </div>
    </section>
  

  )
}
