import './App.css';
import './backgrounds.css';
import Header from './components/Header';
import Carousel from './components/Carousel';
import Mission from './components/Mission';
import Footer from './components/Footer';
import language from './language.json';
import Navbar from './components/Navbar';
import { useState } from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Homepage from './components/Pages/Homepage';
import Miatia from './components/Pages/Miatia';
import About from './components/About';
import Admission from './components/Admission';
import Dashboard from './components/Pages/Dashboard';
import Dwelcome from './components/Dwelcome';
import Parents from './components/Pages/parents/Parents';
import Dcalendar from './components/Pages/parents/Dcalendar';
import EspaceParents from './components/Pages/EspaceParents';
import Devents from './components/Pages/parents/Devents';
import Contact from './components/contact';
import GMap from './components/GMap';
import Progeduc from './components/Progeduc';
import axios from 'axios';
import Dgallery from './components/Dgallery';
import ParentGallery from './components/ParentGallery';
import Dagenda from './components/Pages/parents/Dagenda';

function App() {
  const endpoint ='https://amoursmiatia.com/php/'
  const [miaText, setMiaText] = useState(language.Fr);
  const [agendaLang, setAgendaLang] = useState('fr');
  const [isConnected, setIsConnected] = useState(false);
  const [pIsConnected, setPisConnected] = useState(false);
  const setLanguage =(lang)=>{
    if (lang==="Eng"){
      setMiaText(language.En);
      setAgendaLang('en');
    }
    if (lang==="Fr"){
      setMiaText(language.Fr);
      setAgendaLang('fr');
    }
    
  }
  
   function authenticate(password){
    
    const response = axios.post(endpoint+'miatiaAPI.php', {
        request: 'getlogin',
        password
      })
      .then((response)=>{
        if(response.data === 1){
          setIsConnected(true)
          console.log('admin connected');
        }else{
          alert('invalid password')
        }
        
    });
      
  } 
  function authenticateP(password){
    
    const response = axios.post(endpoint+'miatiaAPI.php', {
        request: 'getloginp',
        password
      })
      .then((response)=>{
        if(response.data === 1){
          setPisConnected(true)
          console.log('Parent connected');
        }else{
          alert('invalid password')
        }
        
    });
      
  } 
  
 
  return (
    <main>
      
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Miatia header={<Header text={miaText.header} nav={<Navbar text={miaText.navbar} func={setLanguage}/>}/>} footer={<Footer text={miaText}/>}/>} >
            <Route index element={<Homepage carousel={<Carousel text={miaText.carousel} />} mission={<Mission text={miaText.home}/>} />}></Route>
            <Route path='About' element={<About text={miaText.About}/>}></Route>
            <Route path='Admissions' element={<Admission text={miaText.admission}/>}></Route>
            <Route path='Parents' element={<EspaceParents connected ={pIsConnected} authentication ={authenticateP} lang={agendaLang}/>}></Route>
            <Route path='Gallery' element={<ParentGallery/>}></Route>
            <Route path='Contact' element={<Contact/>}></Route>
            <Route path='Visiter' element={<GMap/>}></Route>
            <Route path='ProgramEducatif' element={<Progeduc texts={miaText}/>}></Route>
          </Route>
          <Route path='/Admin/*' element={<Dashboard connected ={isConnected} authentication ={authenticate}/>}>
            <Route index element={<Dwelcome/>}></Route>
            <Route path='Parents' element={<Parents/>}></Route>
            {/* <Route path='Agenda' element={<Dcalendar/>}></Route> */}
            <Route path='Agenda' element={<Dagenda/>}></Route>
            <Route path='Dgallery' element={<Dgallery/>}></Route>
            <Route path='DThemes' element={<Devents/>}></Route>
            
          </Route> 
        </Routes>
        
      
      </BrowserRouter>
    
     
    
    </main>
  );
}

export default App;
