import axios from 'axios';
import React,{useState} from 'react'
// import DvideoGallery from './DvideoGallery';

export default function Dgallery() {
    const [selectedFile, setSelectedFile] = useState(null);


    const handleFileInput = (e) => {
      setSelectedFile(e.target.files[0]);
    };
  
    const handleSubmit = (e) => {
        e.preventDefault();
      
        const file = selectedFile;
        const chunkSize = 1000000; // 1 MB
        const fileSize = file.size;
        const numChunks = Math.ceil(fileSize / chunkSize);
        let start = 0;
        let end = chunkSize;
        let chunkIndex = 0;
      
        const sendFile = async (file) => {
            const chunkSize = 1000000; // 1 MB
            const fileSize = file.size;
            const numChunks = Math.ceil(fileSize / chunkSize);
            let start = 0;
            let end = chunkSize;
            let chunkIndex = 0;
          
            while (chunkIndex < numChunks) {
              const chunk = file.slice(start, end);
              const headers = {
                'Content-Type': 'application/octet-stream',
                'X-Chunk-Number': chunkIndex,
                'X-Total-Chunks': numChunks,
                'X-File-Name': file.name,
              };
              const response = await fetch('http://example.com/upload', {
                method: 'POST',
                body: chunk,
                headers,
              });
          
              if (!response.ok) {
                throw new Error(`Failed to upload chunk ${chunkIndex}`);
              }
          
              chunkIndex++;
              start = end;
              end = Math.min(end + chunkSize, fileSize);
            }
          
            console.log('File uploaded successfully!');
          };
          
          // Usage example
          const fileInput = document.querySelector('#file-input');
          fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            sendFile(file);
          });
      
        
      };
  

  

  return (
    <div>
    <form onSubmit={handleSubmit}>
      <input type="file" name="video" onChange={handleFileInput} />
      <button type="submit">Upload</button>
    </form>
  </div>
  )
}


// http://osirisweb.ca/php/miatiavideoupload.php