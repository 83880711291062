import React, { useState, useEffect } from 'react'

export default function Dgetweather() {
    const [weather, setWeather] = useState([]);
    useEffect(() => {
        
        fetch("https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/montreal?unitGroup=metric&key=YR736QMEXFU86K9L64YHATQPP&options=preview&contentType=json", {
  "method": "GET",
  "headers": {
  }
  })
  .then(response => response.json())
  .then(data => setWeather(data.days))
  

  .catch(err => {
    console.error(err);
  });
      }, []);
   
      var myid="";
  
        return (
            
            
            weather.map((item, index) => {
             if (index<5){
           return <div className= {'forecastitem px-2' + (index==0? ' currentW':'')} key={index}><div className="row "><div className="col-12 border-bottom mx-2 py-2" >Date: {item.datetime}</div><div className="col-6 border-bottom py-2">Max : {parseInt(item.tempmax)}</div><div className="col-6 border-bottom py-2">Min : {parseInt(item.tempmin)}</div><div className="col-6 border-bottom py-2">Feels like : {parseInt(item.feelslike)}</div><div className="col-6 border-bottom py-2">Wind speed: {parseInt(item.windspeed)}</div><div className="col-12 py-2">Condition : {item.conditions}</div></div> </div>
            }})
             )
        //console.log(index + "  " + JSON.stringify(item));
     
      

 
}
