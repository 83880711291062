import React from 'react'
import Ecalendar from '../Ecalendar'
import EFoodMenu from '../EFoodMenu'
import PrintFoodMenu from '../PrintFoodMenu'
import calendaricon from '../../assets/calendaricon.svg'
import potfood from '../../assets/potfood.svg'
import espaceicon from '../../assets/espaceicon.svg'
import alert from '../../assets/alert.svg'
import Etheme from '../Etheme'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas';
import { useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'


export default function EspaceParents({connected, authentication, lang}) {
    
    const componentRef = useRef(null);
  
    const [password, setPassword] = useState('');
    const [categorie, setCategorie] = useState('poupons');
console.log('from ep : ',lang);
  const handleChange = (e) =>{
    setPassword(e.target.value)
   }
   const mdpSubmit =(e)=>{
    e.preventDefault();
    authentication(password);

   }
   const translations = {
    en: ["Welcome to the parents corner", 'Calendar', 'Food Menu','Themes/ Activities'],
    fr: ["Bienvenue à l'espace Parents", 'Calendrier', 'Le Menu','Thèmes/ Activités'],
  };

  // Determine the current language and select the translations accordingly
  const translatedTitles = translations[lang] || translations.en;
   const handleDownload = async () => {
    const component = componentRef.current;
    component.style.display = 'block';
  
    const resolution = 150; // Desired resolution in ppi
    const devicePixelRatio = window.devicePixelRatio || 1;
    const scale = resolution / 72 * devicePixelRatio; // Calculate scale based on desired resolution and device pixel ratio
  
    const canvas = await html2canvas(componentRef.current, {
      scale: scale,
    });
  
    const pdfWidth = 8.5 * 72; // 8.5 inches x 72 pixels per inch
    const pdfHeight = 11 * 72; // 11 inches x 72 pixels per inch
    const margin = 50;
    const topMargin = 20;
  
    const canvasWidth = pdfWidth;
    const canvasHeight = canvas.height * canvasWidth / canvas.width;
  
    const pdf = new jsPDF('l', 'pt', [pdfWidth, pdfHeight]);
    pdf.addImage(canvas.toDataURL('image/png'), 'PNG', margin, topMargin, canvasWidth, canvasHeight);
  
    pdf.save('foodMenu.pdf');
    component.style.display = 'none';
  };
    // const handleDownload = async () => {
      
    //   const component = componentRef.current;
    //   component.style.display = 'block';
   

    // const canvas = await html2canvas(componentRef.current, {
    //   scale: 752 / component.offsetWidth,
    // });
    

    // const pdfWidth = 8.5 * 72; // 8.5 inches x 72 pixels per inch
    // const pdfHeight = 11 * 72; // 11 inches x 72 pixels per inch
    // const margin = 50;
    // const topMargin = 10;

    // const canvasWidth = pdfWidth;
    // const canvasHeight = canvas.height * canvasWidth / canvas.width;

    // const pdf = new jsPDF('l', 'pt', [pdfWidth, pdfHeight]);
    // pdf.addImage(canvas.toDataURL('image/png'), 'PNG', margin, topMargin, canvasWidth, canvasHeight);
    
      
    // pdf.save('foodMenu.pdf');
    // component.style.display = 'none';
    
    // };


  return (
    <main className='espacetitle pb-5 pt-5'>
      {!connected&&<section className='d-flex justify-content-center align-items-center w-100'>
        <article className='login-container row'>
        Classe :<select className='form-control' id="categorie" onChange={(e)=>setCategorie(e.target.value)} value={categorie}>
                  <option value="poupons">Petits poupons</option>
                  <option value="coeurs">Petits Coeurs</option>
                  <option value="fleurs">Petites fleures</option>
                  <option value="etoils">Petites étoiles</option>
                  <option value="soleils">Petits Soleils</option>
                  <option value="prematernelle">Prématernelle</option>
               </select>
          <form onSubmit={mdpSubmit}>
            <div className="col-12 d-flex justify-content-center py-2">
              <label htmlFor='mdp'>Cette page est protégée. Entrez votre mot de passe</label>
            </div>
            <div className="col-12 d-flex justify-content-center py-2">
              <input id='mdp' name='mdp' type='password' value={password} onChange={handleChange}/>
            </div>
            <div className="col-12 d-flex justify-content-center py-2">
              <input className='btn btn-primary' type='submit' value='Login'/>
            </div>
            
          </form>

        </article>
      </section>}
      {connected&&<>
    <h1 className='text-success py-5 text-center'><img className='mx-3' src={espaceicon} alt="" width={80} height={80}/> {translatedTitles[0]} </h1>
    <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOne">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
      <div className="d-flex align-items-center">
      <img className='me-2' src={potfood} alt="" width={40} height={40}/><h3 className='text-muted'> {translatedTitles[2]}</h3></div>
      </button>
    </h2>
    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">
      <button className='hidden-link' onClick={handleDownload}>Download PDF</button>
        <EFoodMenu lang={lang}/></div>
       
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
        <div className="d-flex align-items-center"> <img className='me-2' src={calendaricon} alt="" width={40} height={40}/><h3 className='text-muted'> {translatedTitles[1]}</h3></div>
       
      </button>
    </h2>
    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body"><Ecalendar language={lang}/></div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
        <div className="d-flex align-items-center"> <img className='me-2' src={alert} alt="" width={40} height={40}/><h3 className='text-muted'>{translatedTitles[3]} </h3></div>
       
      </button>
    </h2>
    <div id="flush-collapseThree" className="accordion-collapse collapse show ttem" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body d-flex justify-content-center"><Etheme category={categorie}/></div>
    </div>
  </div>
  
</div>
    <PrintFoodMenu myref={componentRef}/>
    </>}
    </main>
  )
}
