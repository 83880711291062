import React from 'react'
import { GoogleMap, useJsApiLoader, MarkerF, MapMouseEvent } from '@react-google-maps/api';
import miatia from '../logomarker.png'
import loading from '../Spinner.gif'



function GMap() {

  const containerStyle = {
    width: '100%',
    height: '80vh',
  };
  
  
  const center = {
    lat: 45.50299297218385,
    lng: -73.84202673507185
  
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDFZ92ksL_NrujpjWqj57b_b1Gq1J5uxcs"
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={16}
      >
        <MarkerF position={center} icon={miatia} title={"Garderie Mia & Tia"} />
        { /* Child components, such as markers, info windows, etc. */ }
        <>
        </>
      </GoogleMap>
  ) : <><div className='d-flex justify-content-center align-items-center'>
  <img src={loading} alt="" />
</div></>
}

export default React.memo(GMap)