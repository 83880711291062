import React from "react";
import { Modal } from "react-bootstrap";

function MyModal({show, onHide, text}) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{text.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img className="img-fluid m-4 mx-auto" src="images/mia22.jpg" alt=""/>
        <p>{text.p1}</p>
        <p>{text.p2}</p>
        </Modal.Body>
      
    </Modal>
  );
}

export default MyModal;