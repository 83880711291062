import React from 'react'
import alphabet from '../assets/alphabetblocks.svg'
import pencils from '../assets/pencils.svg'
import greenblock from '../assets/block.svg'
import welcome from '../assets/welcome.svg'
import hygiene from '../assets/hygiene.svg'
import diversity from '../assets/diversity.svg'
import veggies from '../assets/veggies.svg'

export default function Mission(props) {
  return (
    <section className="container-fluid bgcolorful py-4">
        <h1 className='w-75 text-center amours mx-auto my-5 text-success'>Les Amours de MIA&TIA</h1>
    <div className="container missionicons p-3">
        <div className='row py-2'>
            <div className="col-4 d-flex justify-content-center align-items-vertical"><img className="d-block" width={60} src={alphabet} alt="" /></div>
            <div className="col-4 d-flex justify-content-center align-items-vertical"><img className="d-block" width={60} src={pencils} alt="" /></div>
            <div className="col-4 d-flex justify-content-center align-items-vertical"><img className="d-block" width={60} src={greenblock} alt="" /></div>
        </div>
        <div className='row mt-4 py-2'>
            <div className="col-12 d-flex justify-content-center align-items-vertical"><p>{props.text.info}</p></div>
            
        </div>
        <div className='row mt-4 py-2'>
            <div className="col-12">
                <h2 className='text-success'>{props.text.missiontitle}</h2>
            </div>
            <div className="col-12 ">
                <p className='d-flex align-items-center missionparag'><img className='me-4' width={60} src={welcome} alt="" />{props.text.mission1}</p>
                <p className='d-flex align-items-center missionparag'><img className='me-4' width={60} src={hygiene} alt="" />{props.text.mission2}</p>
                <p className='d-flex align-items-center missionparag'><img className='me-4' width={60} src={diversity} alt="" />{props.text.mission3}</p>
                <p className='d-flex align-items-center missionparag'><img className='me-4' width={60} src={veggies} alt="" />{props.text.mission4}</p>
</div>
            
        </div>
    </div>
    </section>
  )
}
