import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function Dagenda() {
   
    const [title, setTitle] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [entries, setEntries] = useState([]);
    const [loading, setLoading] = useState(true);
    const endpoint = "https://amoursmiatia.com/php/agenda.php";
  
    useEffect(() => {
      fetchEntries();
    }, []);
  
    const fetchEntries = () => {
      axios.get(endpoint)
        .then((response) => {
          setEntries(response.data.entries);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
  
    const handleDelete = (entryId) => {
        fetch("https://amoursmiatia.com/php/delete.php", {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ entryId })
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            fetchEntries();
          })
          .catch((error) => {
            console.error(error);
          });
      };
  
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  
    const handleFileChange = (e) => {
      setSelectedFile(e.target.files[0]);
    };
  
    const handleSubmit = (e) => {
        e.preventDefault();
      
        const formData = new FormData();
        formData.append('title', title);
        formData.append('date', selectedDate);
        formData.append('file', selectedFile);
      
        fetch(endpoint, {
          method: 'POST',
          body: formData
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            fetchEntries();
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setTitle('');
            setSelectedDate(null);
            setSelectedFile(null);
          });
      };
      const DateDisplay = ({ selectedDate, selectedLanguage }) => {
        const formattedDate = new Intl.DateTimeFormat(selectedLanguage, {
          weekday: 'long',
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        }).format(new Date(selectedDate));
        return <div>{formattedDate}</div>;
    };
  return (
      <div>
        <h1 className='text-success'>Agenda</h1>
    <form onSubmit={handleSubmit} className="p-3 addagenda w-75">
    <fieldset>
    <legend>Details</legend>
        <div className='row mb-2'>
      
        <div className="col-3">
            <label>Title:</label>
        </div>
        <div className="col-9">
        <input className='form-control' type="text" name="title" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
      </div>

      <div className='row mb-2'>
      
      <div className="col-3">
      <label>Date:</label>
      </div>
      <div className="col-9">
      <DatePicker className='form-control' selected={selectedDate} name="date" onChange={handleDateChange} dateFormat="dd/MM/yyyy" />
      </div>
    </div>

    <div className='row mb-2'>
      
      <div className="col-3">
      <label>File:</label>
      </div>
      <div className="col-9">
      <input className='form-control' type="file" name='file' onChange={handleFileChange} />
      </div>
    </div>

    
      <button className='my-3 btn btn-primary' type="submit">Add</button>
      </fieldset>
      
    </form>

    <div>
      {loading ? (
        <p>Loading previous entries...</p>
      ) : entries.length > 0 ? (
        <div className='row mt-4 '>
          {entries.map((entry) => (
            <div key={entry.id} className="col-12 my-3 p-3 agendaItem">
              <p>Title: {entry.title}</p>
              <p><DateDisplay selectedDate={entry.date} selectedLanguage="fr" /></p>
              <p><a href={"https://amoursmiatia.com/php/"+entry.file_path} target="_blank"><img src='../images/pdfico.png' width={40} height="auto"/> open / ouvrir</a></p>
              <button onClick={() => handleDelete(entry.id)}>Delete</button>
            </div>
          ))}
        </div>
      ) : (
        <p>No data available.</p>
      )}
    </div>
  </div>
);

}


{/* <div>
<label>Day:</label>
<input type="number" value={selectedDay} onChange={handleDayChange} />
</div>

<div>
<label>Month:</label>
<input type="number" value={selectedMonth} onChange={handleMonthChange} />
</div>

<div>
<label>Year:</label>
<input type="number" value={selectedYear} onChange={handleYearChange} />
</div>

<div>
<label>File:</label>
<input type="file" onChange={handleFileChange} />
</div> */}