import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../logo.png'
import add from '../assets/add.svg'
import manage from '../assets/manage.svg'


export default function DsideBar(props) {
  return (
        <div className="offcanvas offcanvas-start" data-bs-scroll="true" tabIndex="-1" id={props.daid} aria-labelledby="offcanvasWithBothOptionsLabel">
            <div className="offcanvas-header bg-primary">
                <h5 className="offcanvas-title text-warning py-2" id="offcanvasWithBothOptionsLabel"><img className="img-fluid" src={logo} alt="" width = {80} height={80} autoFocus/> Mia & Tia Dashboard</h5>
                <button type="button" className="btn-close text-warning" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <ul className="nav flex-column">
                    <li className="nav-item" data-bs-dismiss="offcanvas">
                        <Link className="nav-link" to="/Admin">Get started</Link>
                    </li>
                    <li className="nav-item" data-bs-dismiss="offcanvas">
                        <Link className="nav-link" to="Agenda">Agenda</Link>
                    </li>
                    {/* <li className="nav-item" data-bs-dismiss="offcanvas">
                        <Link className="nav-link" to="Dgallery">Gallery</Link>
                    </li> */}
                    <li className="nav-item">
                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Parents area</a>
                        <ul className="dropdown-menu">
                            <li data-bs-dismiss="offcanvas"><Link className="dropdown-item" to="Parents"><img src={add} alt="" width={15}/> Food Menu</Link>
                            </li>
                            <li data-bs-dismiss="offcanvas"><Link className="dropdown-item" to="DThemes"><img src={add} alt="" width={15}/> Thèmes / activités</Link></li>
                        </ul>
                
                        
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Admission - suggested function</a>
                        <ul className="dropdown-menu">
                            <li><Link className="dropdown-item" ><img src={add} alt="" width={15}/> Add</Link>
                            </li>
                            <li><a className="dropdown-item" href="#/" ><img src={manage} alt="" width={20}/> Manage</a></li>
                            
                        </ul>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link">communication - to be implemented</a>
                    </li> */}
                   
                </ul>
            </div>
        </div>
  )
}
