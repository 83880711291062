import React from 'react'
import DsideBar from '../DsideBar'
import { Outlet } from 'react-router-dom'
import { useState } from 'react'

export default function Dashboard({connected, authentication}) {
  const [password, setPassword] = useState('');

  const handleChange = (e) =>{
    setPassword(e.target.value)
   }
   const mdpSubmit =(e)=>{
    e.preventDefault();
    authentication(password);

   }

  return (
    <main className='d-flex'>
      {!connected&&<section className='d-flex justify-content-center align-items-center w-100 mt-4'>
        <article className='login-container row'>
          <form onSubmit={mdpSubmit}>
            <div className="col-12 d-flex justify-content-center py-2">
              <label htmlFor='mdp'>Cette page est protégée. Entrez votre mot de passe</label>
            </div>
            <div className="col-12 d-flex justify-content-center py-2">
              <input id='mdp' name='mdp' type='password' value={password} onChange={handleChange}/>
            </div>
            <div className="col-12 d-flex justify-content-center py-2">
              <input className='btn btn-primary' type='submit' value='Login'/>
            </div>
            
          </form>

        </article>
      </section>}


      {connected&&<>
        <article className="p-2 sidepan">
            <button className="btn btn-danger" type="button" data-bs-toggle="offcanvas" data-bs-target="#miaside" aria-controls="offcanvasWithBothOptions"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
            </svg></button>
            <span className='d-block dashlabel' data-bs-toggle="offcanvas" data-bs-target="#miaside" aria-controls="offcanvasWithBothOptions"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
              <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
            </svg> Click here to open the Dashboard options </span>
        </article>
        <article className="container-fluid ps-5">
            <section className="row gx-5">
                <Outlet/>
            </section>
        </article>
        <DsideBar daid="miaside"/>
      </>}
    </main>
    
  )
}
