
import React, { Component } from "react";


const endpoint = 'https://amoursmiatia.com/php/'

export default class Contact extends Component {
    
    
     state={
        fullName:'',
        email:'',
        subject:'',
        message:'',
        sent:false
       }
     handleChange=input => (e) =>{
        this.setState({
         [input]:e.target.value
        })
       }
      

      formSubmit=(e)=>{
        var params = "fullName="+this.state.fullName+"&email="+this.state.email+"&subject="+this.state.subject+"&message="+this.state.message;
        var xhr = new XMLHttpRequest();
        xhr.addEventListener('load',()=>{
            //server response
            console.log(xhr.responseText)

        })
        
        xhr.open('POST',endpoint+'contact.php',true);
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhr.send(params);
        this.resetForm();
        alert("Message Envoyé");
        e.preventDefault();
        
        
      }
    resetForm=()=>{
        this.setState({
            fullName:'',
            email:'',
            subject:'',
            message:''
        })
        setTimeout(()=>{
            this.setState({
                sent:false,
            })
        },3000)
    }



  
    render(){
        return(
            <div className="contact row p-5">
                <h1 id="contactus" className="text-success mb-5 text-center">Nous Contacter</h1>
                
                <div className="col-md-3 col-12">
                    <div className="row">
                        <div className="col-md-3 col-sm-12 text-center text-sm-center text-md-end">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="green" className="bi bi-map" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.502.502 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103zM10 1.91l-4-.8v12.98l4 .8V1.91zm1 12.98 4-.8V1.11l-4 .8v12.98zm-6-.8V1.11l-4 .8v12.98l4-.8z"/>
                            </svg>

                        </div>
                        
                        <div className="col-md-9 col-sm-12 text-center text-md-start text-sm-center">
                            <h3>Garderie Mia Tia</h3>
                            <p>13093 Boul. Gouin Ouest</p>
                            <p>Pierrefonds (QC) H8Z 1X1</p>
                          
                        </div>
                        <div className="col-md-3 col-sm-12 text-center text-sm-center text-md-end">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="green" className="bi bi-telephone" viewBox="0 0 16 16">
                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                        </svg>

                        </div>
                        <div className="col-md-9 col-sm-12 text-center text-md-start text-sm-center">
                            <h3>Tel</h3>
                            <p>(514) 449-9050</p>
                          
                            
                        </div>
                        <div className="col-md-9 col-sm-12 text-center text-sm-center">
                            <a href="https://m.facebook.com/garderiepierrefonds" alt="Garderie miatia page facebook" className="mx-2" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#4267B2" className="bi bi-facebook" viewBox="0 0 16 16">
                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                            </svg>
                            </a>
                            <a href="https://instagram.com/garderie_amours_mia_tia?igshid=NTc4MTIwNjQ2YQ==" alt="Garderie miatia page facebook" className="mx-2" target="_blank">
                            <img height={25} src='images/Instagram.png' alt='Garderie miatia page instagram'/>
                            </a>
                          
                            
                        </div>
                    </div>


                </div>
                <div className="col-sm-12 col-md-9">
                    <form method="post" onSubmit={this.formSubmit}>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                            <input type="text" name="fullName" className="form-control miaform" placeholder="Full name" value={this.state.fullName} onChange={this.handleChange('fullName')} />
                            </div>
                            <div className="col-md-6 col-sm-12">
                            <input type="text" name="email" className="form-control miaform" placeholder="Email address" value={this.state.email} onChange={this.handleChange('email')} />
                            </div>
                        </div>
                        <div className="col-12">
                            <input type="text" name="subject" className="form-control miaform" placeholder="Subject" value={this.state.subject} onChange={this.handleChange('subject')} />
                        </div>
                        <div className="col-12">
                        <textarea name="message" className="form-control miaform" placeholder="Message..." cols="30" rows="5"
                        value={this.state.message} 
                        onChange={this.handleChange('message')}
                        >

                        </textarea>
                        </div>
                        <div className="col-12">
                        <button className="btn btn-success" type="submit">Envoyer</button>
                        </div>
                    </form>
                    </div>

                    
      </div>
    )

}

}